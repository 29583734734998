/**
 * StompClient ermöglicht die Verwaltung einer STOMP-WebSocket-Verbindung.
 *
 * Der Client unterstützt:
 * - Automatische Reconnects bei Verbindungsabbrüchen
 * - Verwaltung und Re-Subscription von Topics
 * - User-spezifische Topics
 * - Mehrfach-Subscriber auf ein Topic (mit Zähler)
 * - Debug-Logging, das optional deaktivierbar ist
 *
 * Abhängigkeiten:
 * - StompJs (STOMP Client)
 * - SockJS (für WebSocket-Kommunikation)
 */
class StompClient {

    /**
     * Erstellt eine neue Instanz von StompClient.
     *
     * @param {string} url - Die URL des STOMP-Endpoints (z.B. '/app/socket').
     * @param {Object} userData - Die Benutzerdaten des angemeldeten Users (enthält mindestens eine `id`).
     * @param {Function} handlerFactory - Optionale Factory-Funktion zur dynamischen Erstellung von Callback-Handlern pro Topic.
     * @param {Function} connectHandler - Callback-Funktion, die beim Verbindungsaufbau ausgeführt wird.
     * @param {Function} disconnectHandler - Callback-Funktion, die beim Verbindungsverlust ausgeführt wird.
     * @param {boolean} [debug=true] - Aktiviert/deaktiviert Debug-Logging.
     */
    constructor(url, userData, handlerFactory, connectHandler, disconnectHandler, debug = true) {
        this.url = url;
        this.userData = userData;
        this.handlerFactory = handlerFactory;
        this.connectHandler = connectHandler;
        this.disconnectHandler = disconnectHandler;

        this.subscriptions = new Map();
        this.pendingSubscriptions = [];

        this.debug = debug;

        // Event-Registry (Map of eventName -> [callbacks])
        this.events = new Map();

        this.createClient();
    }

    /**
     * Gibt Debug-Informationen in die Konsole aus, wenn der Debug-Modus aktiviert ist.
     *
     * @param {...any} args - Die anzuzeigenden Debug-Informationen.
     */
    debugLog(...args) {
        if (this.debug) console.log('[STOMP]', ...args);
    }

    /**
     * Erstellt und aktiviert einen neuen STOMP-Client.
     * Automatische Reconnects und Event-Handler werden hier konfiguriert.
     */
    createClient() {
        this.client = new StompJs.Client({
            webSocketFactory: () => new SockJS(this.url),
            reconnectDelay: 5000,
            debug: (msg) => this.debugLog("[STOMP DEBUG]:", msg),

            onConnect: (frame) => {
                this.debugLog("Verbunden:", frame);
                this.resubscribeAll();

                // Verarbeite pending Subscriptions
                this.pendingSubscriptions.forEach(({topic, callback}) => {
                    this.subscribe({topic, callback});
                });
                this.pendingSubscriptions = [];

                if (this.connectHandler) this.connectHandler(frame);

                // Event: "connect"
                this.emit("connect", frame);
            },

            onDisconnect: (frame) => {
                console.warn("Verbindung getrennt:", frame);
                if (this.disconnectHandler) this.disconnectHandler(frame);

                // Event: "disconnect"
                this.emit("disconnect", frame);
            },

            onStompError: (frame) => {
                console.error("STOMP Fehler:", frame);
                this.emit("error", frame);
            },

            onWebSocketClose: (evt) => {
                console.warn("WebSocket geschlossen:", evt);
                this.emit("websocketClose", evt);

                if (!this.client.active) {
                    console.warn("Client ist inaktiv, versuche Neuverbindung...");
                    this.createClient();
                }
            }
        });

        this.client.activate();
    }


    /**
     * Prüft, ob der STOMP-Client aktuell verbunden ist.
     *
     * @returns {boolean} True, wenn der Client verbunden ist, andernfalls False.
     */
    connected() {
        return this.client && this.client.connected;
    }

    /**
     * Deaktiviert den STOMP-Client und entfernt alle bestehenden Subscriptions.
     * Die Verbindung zum Server wird getrennt.
     */
    deactivate() {
        if (this.client) {
            this.client.deactivate();
            this.subscriptions.forEach((entry, topicKey) => {
                entry.subscription.unsubscribe();
                this.debugLog(`Abo beim Deaktivieren entfernt: ${topicKey}`);
            });
            this.subscriptions.clear();
            this.pendingSubscriptions = [];
        }
    }

    /**
     * Sendet eine Nachricht an das angegebene Topic.
     *
     * @param {string} destination - Das Ziel-Topic (z. B. '/app/chat').
     * @param {Object|string} body - Der Nachrichtentext (wird bei Bedarf in JSON serialisiert).
     */
    publish(destination, body) {
        if (!this.connected()) {
            console.warn("Nicht verbunden. Nachricht nicht gesendet:", destination);
            return;
        }

        const payload = typeof body === 'string' ? body : JSON.stringify(body);
        this.client.publish({destination, body: payload});
        this.debugLog(`Nachricht gesendet an ${destination}:`, payload);
    }

    /**
     * Abonniert ein Topic, um eingehende Nachrichten zu empfangen.
     *
     * @param {Object} sub - Das Subscription-Objekt mit `topic` und optionalem `callback`.
     * @param {string} sub.topic - Das zu abonnierende Topic.
     * @param {Function} [sub.callback] - Die Callback-Funktion für eingehende Nachrichten.
     */
    subscribe({topic, callback}) {
        if (!topic) throw new Error("Topic ist erforderlich.");

        const topicKey = this.resolveUserTopic(topic);

        if (this.subscriptions.has(topicKey)) {
            const existing = this.subscriptions.get(topicKey);
            existing.subscribers += 1;
            this.debugLog(`Bereits abonniert (${topicKey}). Anzahl Subscriber: ${existing.subscribers}`);
            return;
        }

        if (!this.connected()) {
            console.warn(`Noch nicht verbunden, warte mit Subscription: ${topicKey}`);
            this.pendingSubscriptions.push({topic, callback});
            return;
        }

        const subscription = this.trySubscribe(topicKey, callback);
        if (subscription) {
            this.subscriptions.set(topicKey, {
                subscription,
                callback,
                subscribers: 1
            });
            this.debugLog(`Abonniert: ${topicKey}`);
        }
    }


    /**
     * Kündigt ein bestehendes Topic-Abo.
     *
     * @param {string} topic - Das Topic, dessen Abo gekündigt werden soll.
     */
    unsubscribe(topic) {
        const topicKey = this.resolveUserTopic(topic);

        const existing = this.subscriptions.get(topicKey);
        if (!existing) return;

        if (existing.subscribers <= 1) {
            existing.subscription.unsubscribe();
            this.subscriptions.delete(topicKey);
            this.debugLog(`Abo gekündigt: ${topicKey}`);
        } else {
            existing.subscribers -= 1;
            this.debugLog(`Ein Subscriber entfernt (${topicKey}). Verbleibende: ${existing.subscribers}`);
        }
    }

    /**
     * Abonniert alle bestehenden Subscriptions erneut (z. B. nach einer Neuverbindung).
     */
    resubscribeAll() {
        this.debugLog("Alle Subscriptions werden erneut abonniert...");
        this.subscriptions.forEach((value, topicKey) => {
            const {callback} = value;
            const subscription = this.trySubscribe(topicKey, callback);
            this.subscriptions.set(topicKey, {
                ...value,
                subscription
            });
            this.debugLog(`Erneut abonniert: ${topicKey}`);
        });
    }

    /**
     * Interner Helfer, um ein Topic zu abonnieren.
     *
     * @private
     * @param {string} topic - Das Topic, das abonniert werden soll.
     * @param {Function} callback - Die Callback-Funktion, die bei einer Nachricht ausgeführt wird.
     * @returns {StompJs.StompSubscription|null} Das Subscription-Objekt oder null, falls nicht verbunden.
     */
    trySubscribe(topic, callback) {
        if (!this.connected()) {
            console.warn(`Nicht verbunden. Kann Topic nicht abonnieren: ${topic}`);
            return null;
        }

        return this.client.subscribe(topic, (message) => {
            let payload;
            try {
                payload = JSON.parse(message.body);
            } catch (err) {
                payload = message.body;
            }

            // Direkter Callback
            if (callback) {
                callback(payload, message);
            }

            // Handler Factory (falls vorhanden)
            else if (this.handlerFactory) {
                this.handlerFactory(topic)(payload, message);
            }

            // Event-Emitter für das Topic (egal ob direkt oder user-spezifisch)
            this.emit(topic, payload, message);
        });
    }

    /**
     * Fügt dem Topic eine user-spezifische ID hinzu, falls erforderlich.
     *
     * Beispiel: `/user/queue/updates` ➔ `/user/1234/queue/updates`
     *
     * @param {string} topic - Das ursprüngliche Topic.
     * @returns {string} Das aufgelöste Topic, ggf. mit User-ID.
     */
    resolveUserTopic(topic) {
        return topic.includes('/user/')
            ? topic.replace('/user/', `/user/${this.userData.id}/`)
            : topic;
    }

    /**
     * Registriert einen Event-Handler für ein bestimmtes Event oder Topic.
     *
     * @param {string} event - Der Name des Events oder Topics.
     * @param {Function} callback - Die Callback-Funktion, die beim Event aufgerufen wird.
     */
    /**
     * Registriert einen Event-Handler und abonniert das Topic automatisch.
     *
     * @param {string} topic - Der Name des Topics.
     * @param {Function} callback - Die Callback-Funktion.
     */
    on(topic, callback) {
        const resolvedTopic = this.resolveUserTopic(topic);

        if (!this.events.has(resolvedTopic)) {
            this.events.set(resolvedTopic, []);
            if (!this.subscriptions.has(resolvedTopic)) {
                this.subscribe({topic}); // Roh übergeben
            }
        }

        this.events.get(resolvedTopic).push(callback);
        this.debugLog(`Event-Handler registriert und automatisch subscribed: ${topic}`);
    }

    /**
     * Entfernt einen Event-Handler oder alle Handler zu einem Event.
     *
     * @param {string} event - Der Name des Events oder Topics.
     * @param {Function} [callback] - Optional: die spezifische Callback-Funktion, die entfernt werden soll.
     */
    off(topic, callback) {
        if (!this.events.has(topic)) return;

        if (!callback) {
            this.events.delete(topic);
            this.unsubscribe(topic);
            this.debugLog(`Alle Handler entfernt und unsubscribed: ${topic}`);
            return;
        }

        const handlers = this.events.get(topic).filter(fn => fn !== callback);

        if (handlers.length === 0) {
            this.events.delete(topic);
            this.unsubscribe(topic);
            this.debugLog(`Handler entfernt und unsubscribed: ${topic}`);
        } else {
            this.events.set(topic, handlers);
        }
    }

    /**
     * Löst ein Event aus und ruft alle registrierten Handler auf.
     *
     * @param {string} event - Der Name des Events oder Topics.
     * @param {...any} args - Die Argumente, die an die Handler übergeben werden.
     */
    emit(event, ...args) {
        if (!this.events.has(event)) return;

        const handlers = this.events.get(event);
        this.debugLog(`Event ausgelöst: ${event}`, ...args);

        handlers.forEach(handler => {
            try {
                handler(...args, event);
            } catch (err) {
                console.error(`Fehler im Event-Handler für ${event}:`, err);
            }
        });
    }
}
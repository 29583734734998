let ecardPanel;

function prefill(selectedGino) {
    $("#dialog-o-card-pin").val(selectedGino.ocardPin)
    $("#dialog-gino-ip").val(selectedGino.internalIPAddress)
    if (selectedGino.internalIPAddress) {
        const url = $("#gino-url");
        url.attr("href", `https://${selectedGino.internalIPAddress}`)
        url.html(`${selectedGino.name}`)
    }
}

function toggleButton() {
    const oCardPin = $("#dialog-o-card-pin").val();
    const ginoIp = $("#dialog-gino-ip").val();
    $("#createDialogButton").prop("disabled", !(oCardPin && ginoIp));
}

function attachChangeHandler(availableGinos) {
    if (availableGinos && availableGinos.length > 0) {
        prefill(availableGinos[0]); //TODO check defaultDevice option
        toggleButton();
        // refreshABSRequestsTable()
    }


    "change click".split(" ").forEach((e) => {
        document.getElementById('activeGinoSelection').addEventListener(e, (event) => {
            const {value} = event.currentTarget;
            const selectedObject = availableGinos.find((obj) => {
                return `${obj.id}` === value;
            });
            if (selectedObject != null) {
                prefill(selectedObject)
            }
        });
    });

    document.getElementById('ginoSelector').addEventListener('change', (event) => {
        const {value} = event.currentTarget;
        const selectedObject = availableGinos.find((obj) => {
            return `${obj.id}` === value;
        });
        // Display the selected object details
        if (selectedObject != null) {
            const form = $("#gino-form");
            for (let key in selectedObject) {
                if (selectedObject.hasOwnProperty(key)) {
                    let element = $(form.find(`#${key}`));
                    if (element) {
                        let value = selectedObject[key];
                        if (selectedObject[key] instanceof Object && selectedObject[key].id) {
                            value = selectedObject[key].id;
                        }
                        if (typeof value === "boolean") {
                            element.attr("checked", value);
                        } else {
                            element.val(value);
                        }
                    }
                }
            }
            $("#deleteGinoButton").removeClass("hidden");
        } else {
            $("#gino-form").trigger("reset");
            $("#deleteGinoButton").addClass("hidden");
        }
    });

    $('#gino-form').submit(() => {
        let submitButtonId = 'submitNewGinoButton';
        const form = $('#gino-form');
        let oldButtonText = setButtonInWaitingMode(submitButtonId);

        // Formulardaten absenden
        post(form.attr('action'), form.serialize(), {
            contentType: 'application/x-www-form-urlencoded' // Content-Type für Formulardaten
        })
            .then((response) => {
                loadECardModalContent();
                showECardModal(true, {
                    callback: () => {
                        $("#eCardModal .nav-item").removeClass("active");
                        $("#eCardModal .tab-pane").removeClass("active");
                        $("#settings-tab-item").addClass("active");
                        $("#settings").addClass("active");
                    }
                });
                actionStopLoading();
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
            })
            .catch((error) => {
                actionStopLoading();
                wakeButtonFromWaitingMode(submitButtonId, oldButtonText);
                console.error('Fehler beim Absenden des Formulars:', error.message);
            });

        return false; // Verhindert das Standardverhalten des Formulars
    });
}

function showECardModal(forceReload = false, modalOptions, selectedOwnerId = null) {
    if (ecardPanel) {
        ecardPanel.front();
        ecardPanel.unsmallify();
        ecardPanel.reposition({
            my: "center",
        });
        if (forceReload) {
            loadECardModalContent(ecardPanel, modalOptions, selectedOwnerId);
        }
        return;
    }

    let options = {
        footerToolbar: '<span class="pull-right">e-Card Services</span>',
        headerLogo: '<span style="padding:10px" class="glyphicon glyphicon-credit-card" title="e-Card Services" aria-hidden="true"></span>',
        contentSize: {width: "75%", height: "75%"},
        onwindowresize: true,
        closeOnEscape: true,
        borderRadius: 3,
        onbeforeclose: () => {
            ecardPanel = null;
            return true;
        },
        content: function () {
            loadECardModalContent(this, modalOptions, selectedOwnerId);
        },
        headerTitle: "e-Card Services",
        theme: "#f2f2f2",
    };

    ecardPanel = jsPanel.create(options);

    if ($(window).width() <= 1120) {
        ecardPanel.maximize();
    }
}

function loadECardModalContent(panel, options, selectedOwnerId) {
    if (panel) {
        $(panel.content).load("/" + appLocation + "getECardModal", {"selectedOwnerId": selectedOwnerId}, () => {
            $('o-card-pin').focus();
            if (options && options.callback) options.callback();
        });
    }
}

function refreshECardDialogTable(selectedOwnerId) {
    if (ecardPanel) $('#ECardDialogTableReplace').load('/' + appLocation + 'getDialogTable', {"selectedOwnerId": selectedOwnerId});
}

function removeNulls(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
            removeNulls(obj[key]);
        } else if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });
    return obj;
}

/*
 *  e.g. {a:b, c:d} => ?a=b&c=d
 * but remove a if b is null
 */
function createURLParameterString(paramsObject) {
    return new URLSearchParams(removeNulls(paramsObject));
}


function refreshRequestsTable(requestType, statusIds, patientId) {
    if (ecardPanel) {
        const fromSelector = `#${requestType}-requests-from`;
        const toSelector = `#${requestType}-requests-to`;
        const statusSelector = `#${requestType}RequestStatus`;
        const loadingSpinnerSelector = `#${requestType}-request-loading-spinner`;
        const tabPaneSelector = `#${requestType}-requests`;
        const actionsTabSelector = `#${requestType}-requests-actions-tab-item`;

        const params = createURLParameterString({
            from: $(fromSelector).datetimepicker().data('DateTimePicker').date()?.format("YYYY-MM-DD"),
            to: $(toSelector).datetimepicker().data('DateTimePicker').date()?.format("YYYY-MM-DD"),
            ids: statusIds,
            patientId,
            status: $(statusSelector).val() !== "-1" ? $(statusSelector).val() : undefined,
        });
        let query = params.size > 0 ? `?${params.toString()}` : "";
        let gino = Number($("#activeGinoSelection").val());
        const loadingSpinner = $(loadingSpinnerSelector);
        loadingSpinner.show();
        $(`#${requestType}RequestsTableReplace`).load(`/${appLocation}get${requestType.toUpperCase()}Requests/${gino}${query}`, () => {
            if ($(tabPaneSelector).hasClass("active") && $(actionsTabSelector).hasClass("active")) {
                $("#eCardModal .nav-item").removeClass("active");
                $("#eCardModal .tab-pane").removeClass("active");
                $(tabPaneSelector).addClass("active");
                $(actionsTabSelector).addClass("active");
                loadingSpinner.hide();
            }
        });
    }
}

function refreshECardMessagesTable() {
    if (ecardPanel) {
        let gino = Number($("#activeGinoSelection").val());
        let loadingSpinner = $("#ecard-messages-loading-spinner");
        loadingSpinner.show();
        $('#ECardMessagesTableReplace').load(`/${appLocation}getEcardMessagesDatatable/${gino}`, () => {
            if ($("#ecard-messages").hasClass("active") && $("#ecard-messages-actions-tab-item").hasClass("active")) {
                $("#eCardModal .nav-item").removeClass("active");
                $("#eCardModal .tab-pane").removeClass("active");
                $("#ecard-messages").addClass("active");
                $("#ecard-messages-actions-tab-item").addClass("active");
                loadingSpinner.hide();
                if ($(".eCardMessagesCountBlock").length)
                    $(".eCardMessagesCountBlock").load("/" + appLocation + "getECardMessagesCount");
            }
        });
    }
}

async function navigateToPatientView(svnr) {
    try {
        const response = await get(`/${appLocation}patient/${svnr}`);
        if (response.status === "SUCCESS")
            window.location = `${appLocation}PatientView/${response.result}`;
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }
}


function showABSRequestDetails(absRequestId) {
    if (ecardPanel) {
        let gino = Number($("#activeGinoSelection").val());
        let detailsReplace = $("#ABSRequestDetailsReplace");
        let detailsSpinner = $("#abs-request-details-loading-spinner");
        detailsSpinner.show();
        detailsReplace.hide();
        detailsReplace.load(`/${appLocation}getABSRequestsDetails/${gino}/${absRequestId}`, () => {
            $("#eCardModal .nav-item").removeClass("active");
            $("#eCardModal .tab-pane").removeClass("active");
            $("#abs-requests").addClass("active");
            $("#abs-requests-actions-tab-item").addClass("active");
            detailsSpinner.hide();
            detailsReplace.show();
            document.getElementById("ABSRequestDetailsReplace").scrollIntoView();


        });
    }
}

/**
 * Opens a new E-Card Dialog using the GINO with the given IP and the given PIN for the O-Card.
 *
 */
async function createECardDialogWithOCard(ginoIp, pin, gino, createDialogButtonId, disabled) {
    actionStartLoading();
    const button = $(`#${createDialogButtonId}`);

    button.attr("disabled", true);
    const result = await Gino.get(ginoIp).statusWithOCard(pin);
    const oCardResult = result.find(it => it?.registered === "o-card");
    if (oCardResult) await createECardDialogWithToken(oCardResult, gino, createDialogButtonId, disabled)
    else actionFail("Keine Admin Karte gefunden!")
    actionStopLoading();
    button.removeAttr("disabled");
}

async function waitForECardFromGino(ginoIp, callback) {
    const result = await Gino.get(ginoIp).waitForECard();
    if (result && result.registered === "e-card" && result.cardData && callback) {
        callback(result.cardData)
    } else if (result && result.registered !== "e-card") {
        actionFail("GINO Fehler: Keine e-Card gefunden. Bitte prüfen Sie, ob die richtige Karte des Patienten gesteckt wurde.");
    }
}

async function waitForECardFromGinoAndFetchToken(ginoIp, ginoPin, callback, vpnr) {
    const eCard = await Gino.get(ginoIp).waitForECard();

    if (eCard && eCard.registered === "e-card" && eCard.cardData && callback) {
        if (vpnr) {
            const status = await Gino.get(ginoIp).getECardToken(eCard.cardData.nummer, vpnr);
            let result = status.find((it) => it?.registered === 'o-card')
            eCard.cardData.cardToken = result.token.value;
        }
        callback(eCard.cardData);
    } else if (result && result.registered !== "e-card") {
        actionFail("GINO Fehler: Keine e-Card gefunden. Bitte prüfen Sie, ob die richtige Karte des Patienten gesteckt wurde.");
    }
}

async function createECardDialogWithToken(json, gino, createDialogButtonId, disabled = false) {
    if (json && "token" in json) {
        const cardData = json.cardData;
        if (cardData)
            cardData.cardToken = json.token.value;

        try {
            await post(`/${appLocation}createECardDialog`, {cardToken: json.token.value, gino, cardData});
            if (!disabled) showECardModal(true);
            setECardBadgeStatus(true);
            actionStopLoading();
            $(`#${createDialogButtonId}`).removeAttr("disabled");
        } catch (error) {
            actionFail('e-Card Fehler: ' + error.message);
            $(`#${createDialogButtonId}`).removeAttr("disabled");
        }
    } else {
        actionFail("GINO Fehler: Kein Token\nBitte prüfen Sie, ob die richtige Karte gesteckt wurde.");
        $(`#${createDialogButtonId}`).removeAttr("disabled");
    }
}


// val gino: Long, val dialogId: String, val type: String, val value: String
async function changeDialogDataPreselect(selector, type) {
    const gino = Number($("#activeGinoSelection").val());
    const dialogId = $(selector).parents("tr").attr('data-dialogId')
    const value = $(selector).val();
    try {
        await patch(`/${appLocation}changeDialogDataPreselect`, {gino, dialogId, type, value});
        showECardModal(true);
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }
}


/**
 * Create a eRezept for a patient and fachgebietCode using the GINO with the fiven IP and the given PIN for the O-Card.
 * A Dialog must be open in order to be able to create an eRezept.
 *
 * @param patientId
 * @param fachgebietCode
 * @param kvtCode
 */
async function createERezept(patientId) {

    try {
        await post(`/${appLocation}createBlankoWahlarztRezeptMitPersonenBezugUsingSvnr`, {'patientId': patientId});
        loadById('Documents', currentPatientContext);
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }

}

/**
 * Create a eRezept  with fachgebietCode using the GINO with the given IP and the given PIN for the O-Card.
 * A Dialog must be open in order to be able to create an eRezept.
 */
async function createBlankoRezept() {
    const fachgebietCode = $("#eRezeptFachgebietCodeSelection").val();
    const kvtCode = $("#eRezeptKvtCodesSelection").val();
    try {
        const result = await post(`/${appLocation}createBlankoWahlarztRezeptOhnePersonenBezug`, {
            fachgebietCode,
            kvtCode
        });
        $("#blankoPrescriptionsLinkList").prepend(`<a href="/${appLocation}download/blankoprescriptions/${result.name}">* Soeben erstellt: Blanko Rezepte herunterladen | ${result.lastModified}</a><br/>`)
        // if(currentPatientContext)loadById('Documents', currentPatientContext);
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }

}

function deleteECardDialogs() {
    customConfirm("Vom e-Card-System abmelden", "Möchten Sie sich wirklich vom e-Card-System abmelden?", async () => {
        try {
            await del(`/${appLocation}deleteECardDialogs`);
            showECardModal(true);
            setECardBadgeStatus(false);
        } catch (error) {
            actionFail('Fehler: ' + error.message);
        }
    });
}

function deleteExpiredDialogs() {
    customConfirm("Alle abgelaufenen Dialoge löschen", "Möchten Sie sich wirklich alle abgelaufenen Dialoge löschen?", async () => {

        try {
            await del(`/${appLocation}deleteExpiredCardDialogs`);
            showECardModal(true);
        } catch (error) {
            actionFail('Fehler: ' + error.message);
        }

    })
}

function deleteGino() {
    const name = $("#name").val();
    const id = $("#id").val();
    customConfirm('Bestätigen', `Möchten Sie den ausgewählten Gino: <b>${name}</b> wirklich löschen?`, async () => {
        try {
            await del(`/${appLocation}gino/${id}`);
            showECardModal(true);
        } catch (error) {
            actionFail('Fehler: ' + error.message);
        }
    });
}

async function unblockDialog(dialogId) {
    customConfirm('Bestätigen', `Möchten Sie den ausgewählten Dialog: <b>${name}</b> wirklich entsperren?`, async () => {
        try {
            await patch(`/${appLocation}dialog/unblock/${dialogId}`, eCard.cardData);
            showECardModal(true);
        } catch (error) {
            actionFail('Fehler: ' + error.message);
        }
    });
}

function setECardBadgeStatus(active) {
    let badge = $('#eCardBadge');
    (active) ? badge.addClass('active') : badge.removeClass('active');
}

function toggleDrugModalFormFields() {
    let eRezept = $('#DrugsModal input[name="issueERecipe"]:checked').val() === "true";
    let ePrivatRezept = $('#DrugsModal input[name="eRecipeType"]:checked').val() === "P";

    if (eRezept) {
        $('.papierRezeptAttribut').hide();
        $('.eRezeptAttribut').show();
        if (ePrivatRezept)
            $('.ePrivatRezeptAttribut').show();
        else
            $('.ePrivatRezeptAttribut').hide();
    } else {
        $('.papierRezeptAttribut').show();
        $('.eRezeptAttribut').hide();
        $('.ePrivatRezeptAttribut').hide();
    }

    $('#DrugsModal').modal('handleUpdate');
    //onchange="$('.ePrivatRezeptAttribut').toggle(); $('#DrugsModal').modal('handleUpdate');"
}

async function submitViaECardService(docId) {
    try {
        await post(`/${appLocation}createDatenblattErgebnis/${docId}`);
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }
}

async function startBehandlungsfall(cardToken, patientId) {
    try {
        await post(`/${appLocation}startBehandlungsfall`, {cardToken, patientId});
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }
}


//TODO: use this gino object in the future
// except if there is an author for a dokument
// plus use vpn from user settings
function getSelectedGino() {
    const selecetdGino = $("#selectedGino");
    return {
        id: selecetdGino.val(),
        name: selecetdGino.innerHTML,
        internalIp: selecetdGino.attr('data-internal-ip'),
        publicIp: selecetdGino.attr('data-public-ip'),
        pin: selecetdGino.attr('data-ocard-pin')
    }
}

async function startECardConnection(patientSVNR, patientId, ginoIp, vpnr) {
    const status = await Gino.get(ginoIp).getECardToken(patientSVNR, vpnr);
    // const oCard = status.find((it) => it?.registered === 'o-card');
    const eCard = status.find((it) => it?.registered === 'e-card');
    eCard.cardData.cardToken = eCard.token.value;
    try {
        const result = await post(`/${appLocation}startECardConnection/${patientId}`, eCard.cardData);
        $("#currentPatientCardToken").val(result.cardToken);
        $(".requires-card-token").show();
    } catch (error) {
        actionFail('Fehler: ' + error.message);
        return false;
    }
    return true;
}

async function konsultationStornieren(id, version) {
    try {
        await patch(`/${appLocation}konsultationStornieren`, {id, status: version});
    } catch (error) {
        actionFail();
    }
}


async function deleteKonsultationsdatenAnfrage(anfrageId) {
    try {
        await del(`/${appLocation}deleteKonsultationsdatenAnfrage/${anfrageId}`);
        $(`#konsultationsbeleg-${anfrageId}`).remove()
    } catch (error) {
        actionFail('Fehler: ' + error.message);
    }

}


function onECardMessage(message) {
    if ($(".eCardMessagesCountBlock").length)
        $(".eCardMessagesCountBlock").load("/" + appLocation + "getECardMessagesCount");

    const eCardPopup = MaoNotification({
        position: 'top-right',
        duration: 15000
    });

    eCardPopup.dialog({
        title: "e-Card Nachricht",
        message: message.text,
        buttons: {ok: {label: "ABS Anfrage anzeigen"}},
        callback: (event) => {
            if (event === "ok") showABSRequestStatus(message.data)
        }
    });
}




